<template>
  <b-container class="mt-4" v-on:keyup="keyMonitor">
    <img v-if="tricks.image.show"
         class="text-center align-middle"
         alt="homescreen_img"
         @click="changeImage"
         :src="require(`@/assets/images/values/${imagePick}.png`)">

    <b-row v-if="tricks.e.show">
      <b-col cols="12" class="text-center align-middle">
        <iframe width="700" height="500"
                src="https://www.youtube.com/embed/WxjFBybiiR4?autoplay=1"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </b-col>
    </b-row>

    <b-row v-if="tricks.r.show">
      <b-col cols="12" class="text-center align-middle">
        <iframe width="700" height="500"
                src="https://www.youtube.com/embed/Hy8kmNEo1i8?autoplay=1"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
      isLoggedIn: false,
      imagePick: 1,
      tricks: {
        image: {
          show: true,
        },
        e: {
          count: 0,
          show: false,
        },
        r: {
          count: 0,
          show: false,
        },
      },
    };
  },
  computed: {
    loggedIn() {
      return this.$acl.check('view');
    },
  },
  methods: {
    changeImage() {
      this.imagePick = Math.floor(Math.random() * 6) + 1;
    },
    keyMonitor(event) {
      if (!this.tricks[event.key]) {
        return;
      }

      this.tricks[event.key].count += 1;
      if (this.tricks[event.key].count === 3) {
        this.tricks.image.show = false;
        this.tricks.e.show = false;
        this.tricks.r.show = false;
        this.tricks[event.key].show = true;
        this.tricks[event.key].count = 0;
      }
    },
  },
  beforeMount() {
    this.changeImage();
    document.addEventListener('keyup', this.keyMonitor);
  },
};
</script>
<style lang="scss" scoped>
img {
  width: 70%;
  margin: 0 auto;
  display: block;
}
</style>
